<template>
  <button
    class="group bg-black/[0.16] w-8 h-8 rounded-full inline-flex items-center justify-center hover:bg-black/[0.34] border border-transparent hover:border-opacity-100 hover:border-white/[0.20] transition-colors"
  >
    <div
      class="bg-white rounded-full inline-flex items-center justify-center w-3 h-3 group-hover:scale-[calc(2/3)] transition-transform"
    ></div>
  </button>
</template>

<script setup lang="ts"></script>
